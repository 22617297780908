import { takeLatest, put, call, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions/batch.action';
import request from '../../helpers/request';
import { batchTypes } from '../types/batch.types';
import { IDocType } from '../../interfaces/batch.interface';

function* searchByNumber(action: { [key: string]: any }) {
    try {
        const { callback } = action.payload;
        const { data } = yield call(request, action.request);
        yield put(actions.batchSearchSuccess(data));
        yield callback(data.batch);
    } catch (e) {
        const { id, callback, autoCreate } = action.payload;
        const { data } = e.response;
        if (data.detail.includes('Not found')) {
            if (autoCreate) {
                yield put(actions.createBatchRequest({ batch: id }, callback));
            } else {
                yield put(actions.batchSearchFailure('Entered batch could not found'));
            }
        }
    }
}

function* createNewBatch(action: { [key: string]: any }) {
    try {
        const { callback } = action.payload;
        const { data } = yield call(request, action.request);
        yield put(actions.createBatchSuccess(data));
        yield callback(data.batch);
    } catch (e) {
        const { data } = e.response;
        yield put(actions.createBatchFailure(data.detail));
    }
}

function* addDocToBatch(action: { [key: string]: any }) {
    try {
        const { callback } = action.payload;
        const { data } = yield call(request, action.request);
        yield put(actions.addDocToBatchSuccess(data));
        yield callback(data);
    } catch (e) {
        const { data } = e.response;
        yield put(actions.addDocToBatchFailure(data.detail));
    }
}

function* addVideoToBatch(action: { [key: string]: any }) {
    try {
        const { data } = yield call(request, action.request);
        yield put(actions.addVideoToBatchSuccess(data));
    } catch (e) {
        yield put(actions.addVideoToBatchFailre('Failed to add a video, try again'));
    }
}

function* editVideoToBatch(action: { [key: string]: any }) {
    try {
        const { data } = yield call(request, action.request);
        yield put(actions.batchEditVideoSuccess(data));
    } catch (e) {
        yield put(actions.batchEditVideoFailure('Failed to edit the video'));
    }
}

function* deleteVideoFromBatch(action: { [key: string]: any }) {
    try {
        const { id } = action.payload;
        yield call(request, action.request);
        yield put(actions.deleteVideoFromBatchSuccess(id));
    } catch (e) {
        yield put(actions.deleteVideoFromBatchFailure('Failed to delete the video'));
    }
}

function* deleteDocFromBatch(action: { [key: string]: any }) {
    try {
        const { callback, id } = action.payload;
        yield call(request, action.request);
        yield put(actions.deleteDocFromBatchSuccess(id));
        yield callback();
    } catch (e) {
        const { data } = e.response;
        yield put(actions.deleteDocFromBatchFailure(data.detail));
    }
}

function* getVideoTypes(action: { [key: string]: any }) {
    try {
        const { data } = yield call(request, action.request);
        yield put(actions.batchGetVideoTypesSuccess(data));
    } catch (e) {
        yield put(actions.batchGetVideoTypesFailure('Cant get video types, try again.'));
    }
}

function* gerDocTypes(action: { [key: string]: any }) {
    try {
        const { data } = yield call(request, action.request);
        const ANALISYS_REPORTS = 'Certificate of analysis';
        const analysis = data.find((item: IDocType) => item.type === ANALISYS_REPORTS);
        const sortedData: IDocType[] = analysis
            ? [analysis, ...data.filter((item: IDocType) => item.type !== ANALISYS_REPORTS)]
            : data;
        yield put(actions.batchGetDocTypesSuccess(sortedData));
    } catch (e) {
        const { data } = e.response;
        yield put(actions.batchGetDocTypesFailure(data.detail));
    }
}

function* gerBatches(action: { [key: string]: any }) {
    try {
        const { data } = yield call(request, action.request);
        yield put(actions.getBatchesSuccess(data));
    } catch (e) {
        const { data } = e.response;
        yield put(actions.getBatchesFailure(data.detail));
    }
}

function* searchSaga() {
    yield takeLatest(batchTypes.BATCH_SEARCH_REQUEST, searchByNumber);
    yield takeLatest(batchTypes.BATCH_CREATE_REQUEST, createNewBatch);
    yield takeLatest(batchTypes.ADD_DOC_TO_BATCH_REQUEST, addDocToBatch);
    yield takeLatest(batchTypes.BATCH_GET_DOCTYPES_REQUEST, gerDocTypes);
    yield takeLatest(batchTypes.BATCH_GET_VIDEO_TYPES_REQUEST, getVideoTypes);
    yield takeLatest(batchTypes.ADD_VIDEO_TO_BATCH_REQUEST, addVideoToBatch);
    yield takeLatest(batchTypes.BATCH_EDIT_VIDEO_REQUEST, editVideoToBatch);

    yield takeEvery(batchTypes.BATCH_DELETE_DOC_REQUEST, deleteDocFromBatch);
    yield takeEvery(batchTypes.GET_BATCHES_REQUEST, gerBatches);
    yield takeEvery(batchTypes.BATCH_DELETE_VIDEO_REQUEST, deleteVideoFromBatch);
}

export default searchSaga;
