import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Search from '../../assets/images/search-logo.png';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #122a4c;
    display: flex;
    @media (max-width: 800px) {
        flex-direction: column-reverse;
    }
`;

export const ImageBlock = styled.div`
    height: 100%;
    width: 30%;
    background-image: url(${Search});
    background-position: center;
    background-size: cover;
    @media (max-width: 800px) {
        width: 100%;
        margin-bottom: -100px;
    }
`;

export const ContentWrapper = styled.div`
    width: 70%;
    height: 100%;
    padding: 4% 0;
    @media (max-width: 800px) {
        padding: 0 4% 4% 4%;
        width: 100%;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    margin-left: 8.6%;
    display: flex;
    position: relative;
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    padding: 50px 6%;
    background-color: #ffffff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 3px 20px #00000029;
    @media (max-width: 1120px) {
        padding: 40px 5%;
    }
    @media (max-width: 800px) {
        margin-left: 0;
        padding: 25px 3%;
    }
    @media (max-width: 500px) {
        padding: 14px;
        height: auto;
    }
`;

export const Logo = styled.img`
    width: 210px;
    height: auto;
    margin-bottom: 60px;
    @media (max-width: 800px) {
        width: 33%;
        margin-top: 25px;
    }
`;

export const Title = styled.div`
    color: #122a4c;
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 15px;
    @media (max-width: 1120px) {
        font-size: 3rem;
    }
    @media (max-width: 820px) {
        font-size: 2.6rem;
    }
    @media (max-width: 500px) {
        font-size: 2rem;
        line-height: 27px;
        margin-bottom: 6px;
    }
`;

export const Subtitle = styled.div`
    color: #4d4d4d;
    max-width: 60%;
    line-height: 25px;
    font-size: 1.6rem;
    margin-bottom: 50px;
    @media (max-width: 1120px) {
        font-size: 1.4rem;
        max-width: 80%;
    }
    @media (max-width: 820px) {
        max-width: 100%;
    }
    @media (max-width: 500px) {
        font-size: 1.1rem;
        line-height: 20px;
        margin-bottom: 40px;
    }
`;

export const SearchContainer = styled.div<{ error: string; dir: string }>`
    display: flex;
    width: 100%;
    height: 80px;
    position: relative;
    @media (max-width: 1280px) {
        height: 70px;
    }
    @media (max-width: 1120px) {
        height: 65px;
        flex-direction: column;
        height: auto;
        align-items: center;
    }
    @media (max-width: 820px) {
        height: 60px;
    }

    @media (max-width: 500px) {
    }
    ${({ error, dir }) =>
        error.length
            ? `::after {
        content: '${error}';
        position: absolute;
        top: 100%;
        text-align: left;
        font: normal normal normal 14px/30px Open Sans;
        letter-spacing: 0px;
        color: #EB0136;
        opacity: 1;
        ${dir === 'rtl' ? 'right: 8px;' : 'left: 8px;'}
        @media (max-width: 500px) {
          top: 50px;
          left: 2px;
          font: normal normal normal 12px/30px Open Sans;
        }
      }`
            : null};
`;

export const SearchField = styled.input<{ error: string }>`
    color: #4d4d4d;
    line-height: 30px;
    font-size: 2rem;
    padding: 0 25px;
    background: #ffffff;
    box-shadow: 0px 3px 20px #0000002f;
    -webkit-box-box-shadow: 0px 3px 20px #0000002f;
    -moz-box-box-shadow: 0px 3px 20px #0000002f;
    -webkit-appearance: none;
    margin-right: 20px;
    flex-grow: 1;
    border: 1px solid transparent;
    outline: none;
    min-width: 0;
    ::placeholder {
        color: #8e8e8e;
    }
    @media (max-width: 1120px) {
        width: 100%;
        margin-bottom: 55px;
        margin-right: 0;
    }
    @media (max-width: 1280px) {
        min-height: 50px;

        font-size: 1.4rem;
        width: 100%;
    }

    @media (max-width: 820px) {
        font-size: 1.6rem;
    }

    &[dir='rtl'] {
        margin-left: 20px;
        margin-right: 0;
        @media (max-width: 500px) {
            margin-left: 0;
        }
    }
    ${({ error }) => (error.length ? 'border: 1px solid #EB0136' : null)};
`;

export const SearchButton = styled.button`
    line-height: 30px;
    font-size: 2rem;
    background: #122a4c;
    width: 200px;
    border: none;
    outline: none;
    color: #ffffff;
    height: 100%;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    min-width: 120px;
    @media (max-width: 1280px) {
        font-size: 1.8rem;
    }
    @media (max-width: 1120px) {
        min-height: 40px;
        width: 123px;
        font-size: 1.6rem;
        margin-bottom: 90px;
    }
    ${({ disabled }) => (disabled ? 'cursor: auto; background: #122A4C; opacity: 0.3' : null)}
`;

export const LanguageContainer = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
    &[dir='rtl'] {
        left: auto;
        right: 5px;
    }
`;

export const List = styled.div`
    display: flex;
    flex-direction: column;
    background: #f5f5f5;
    color: #122a4c;
    padding: 50px 20px 0 20px;
    min-width: 280px;
`;

export const ListTitle = styled.div`
    font-weight: 600;
    margin: 0 0 40px 15px;
    font-size: 1.8rem;
    &[dir='rtl'] {
        margin: 0 15px 40px 0;
    }
`;

export const ListItems = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    overflow: auto;
    & > * {
        margin-bottom: 5px;
    }
    & > *:last-child {
        margin-bottom: 40px;
    }
`;

export const ListItem = styled.div`
    padding: 8px 15px;
    cursor: pointer;
    background: #f5f5f5;
    transition: 0.3s ease background;
    font-size: 1.8rem;
    display: flex;
    align-items: center;

    :hover {
        background: #eaeaea;
        transition: 0.3s ease background;
    }
`;
