import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getFileName } from '../../../../helpers/getFileName';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { useDirection } from '../../../../hooks/useDirection';
import { IBatchDocument } from '../../../../interfaces/batch.interface';
import { Button } from '../../../../shared/Button';
import { Modal } from '../../../../shared/Modal';
import { batchLoaderSelector } from '../../../../store/selectors/loader.selectors';
import * as Styled from './style';

interface IProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    doc: IBatchDocument | null;
}

export const DeleteModal: FC<IProps> = ({ open, onClose, onConfirm, doc }) => {
    const loader = useSelector(batchLoaderSelector);

    const { t } = useCustomTranslation('');
    const direction = useDirection();

    const confirmHandler = () => {
        onConfirm();
    };

    return (
        <Modal open={open}>
            <Styled.Content>
                <Styled.Title dir={direction}>{t('Remove Document')}</Styled.Title>
                <Styled.Subtitle dir={direction}>
                    {t('About Remove')} {<Styled.FileName>{getFileName(doc?.file || '')}</Styled.FileName>}
                </Styled.Subtitle>
                <Styled.Subtitle className="last" dir={direction}>
                    {t('Are You Sure To Remove')}
                </Styled.Subtitle>
                <Button
                    title={t(`Yes, I'm Sure`)}
                    onClick={confirmHandler}
                    loading={loader}
                    customStyles={{ width: 165, height: 50, marginBottom: 30 }}
                />
                <Styled.CheckButton dir={direction} onClick={onClose}>
                    {t('Cancel')}
                </Styled.CheckButton>
            </Styled.Content>
        </Modal>
    );
};
