import styled, { css } from 'styled-components';
import { ReactComponent as Loader } from '../../assets/icons/loader.svg';

interface IButton {
    color: string;
    isDisable: boolean;
}

const detectButtonType = (type: string) => {
    switch (type) {
        default:
            return ' background-color: #122A4C; color: #fff;';
    }
};

export const Container = styled.div``;
export const ButtonComponent = styled.button<IButton>`
    ${({ color }) => detectButtonType(color)};
    outline: none;
    border: none;
    width: 150px;
    height: 40px;
    font-weight: 600;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px;
    cursor: pointer;
    ${({ isDisable }) =>
        isDisable &&
        css`
            cursor: not-allowed;
            background: #122a4c;
            opacity: 0.3;
        `}
`;

export const ButtonLoader = styled(Loader)`
    width: 100%;
    height: 100%;
`;
