import { IBatch, IDocType, IBatchDocument, IVideoType } from '../../interfaces/batch.interface';
import { batchTypes } from '../types/batch.types';

interface IInitialState {
    batch: IBatch | null;
    error: string;
    docTypes: IDocType[];
    batches: IBatch[];
    videoTypes: IVideoType[];
}

const initialState: IInitialState = {
    batch: null,
    error: '',
    docTypes: [],
    batches: [],
    videoTypes: []
};

const batchReducer = (state = initialState, { payload, type }: any) => {
    switch (type) {
        case batchTypes.BATCH_SEARCH_REQUEST: {
            return {
                ...state,
                batch: null,
                error: ''
            };
        }
        case batchTypes.BATCH_SEARCH_FAILURE: {
            return {
                ...state,
                batch: null,
                error: payload
            };
        }

        case batchTypes.BATCH_SEARCH_SUCCESS: {
            return {
                ...state,
                error: '',
                batch: payload
            };
        }

        case batchTypes.BATCH_SEARCH_RESET: {
            return {
                ...state,
                error: '',
                batch: null
            };
        }

        case batchTypes.BATCH_GET_DOCTYPES_FAILURE: {
            return {
                ...state,
                docTypes: [],
                error: payload
            };
        }

        case batchTypes.BATCH_GET_DOCTYPES_SUCCESS: {
            return {
                ...state,
                error: '',
                docTypes: payload
            };
        }

        case batchTypes.ADD_VIDEO_TO_BATCH_SUCCESS: {
            return {
                ...state,
                error: '',
                batch: {
                    ...state.batch,
                    videos: [...(state.batch?.videos ?? []), payload]
                }
            };
        }

        case batchTypes.BATCH_EDIT_VIDEO_SUCCESS: {
            return {
                ...state,
                error: '',
                batch: {
                    ...state.batch,
                    videos: state.batch?.videos.map((video) => (video.id === payload.id ? payload.data : video))
                }
            };
        }

        case batchTypes.BATCH_DELETE_VIDEO_SUCCESS: {
            return {
                ...state,
                error: '',
                batch: {
                    ...state.batch,
                    videos: state.batch?.videos.filter((video) => video.id !== payload)
                }
            };
        }

        case batchTypes.ADD_VIDEO_TO_BATCH_FAILURE:
        case batchTypes.BATCH_EDIT_VIDEO_FAILURE:
        case batchTypes.BATCH_DELETE_VIDEO_FAILURE: {
            return {
                ...state,
                batch: null,
                error: payload
            };
        }

        case batchTypes.BATCH_GET_VIDEO_TYPES_SUCCESS: {
            return {
                ...state,
                error: '',
                videoTypes: payload
            };
        }

        case batchTypes.BATCH_GET_VIDEO_TYPES_FAILURE: {
            return {
                ...state,
                videoTypes: [],
                error: payload
            };
        }

        case batchTypes.ADD_DOC_TO_BATCH_SUCCESS: {
            return {
                ...state,
                error: '',
                batch: {
                    ...state.batch,
                    documents: state.batch?.documents ? [...state.batch.documents, payload] : [payload]
                }
            };
        }

        case batchTypes.GET_BATCHES_REQUEST: {
            return { ...state, error: '' };
        }

        case batchTypes.GET_BATCHES_SUCCESS: {
            return { ...state, error: '', batches: payload };
        }

        case batchTypes.GET_BATCHES_FAILURE: {
            return { ...state, error: payload, batches: [] };
        }

        case batchTypes.BATCH_DELETE_DOC_SUCCESS: {
            return {
                ...state,
                error: '',
                batch: {
                    ...state.batch,
                    documents: state.batch?.documents
                        ? [...state.batch?.documents.filter((el: IBatchDocument) => el.id !== payload)]
                        : []
                }
            };
        }

        default:
            return state;
    }
};

export default batchReducer;
