import { Documents } from '../views/Documents';
import { Login } from '../views/Login';
import { Search } from '../views/Search';

export const routes = [
    {
        name: 'Search',
        component: Search,
        isPrivate: true,
        exact: true,
        path: '/search'
    },
    {
        name: 'Login',
        component: Login,
        exact: true,
        path: '/login'
    },
    {
        name: 'Documents',
        component: Documents,
        exact: true,
        path: '/document/:id',
        isPrivate: true
    },
    {
        name: 'Documents',
        component: Documents,
        exact: true,
        path: '/document/:id/:document',
        isPrivate: true
    }
];
