import styled from 'styled-components';

export const Header = styled.div`
    width: 100%;
    height: 70px;
    background: #122a4c;
    padding: 18px 55px;
    display: flex;
    align-items: center;
`;

export const Logo = styled.img`
    width: auto;
    height: 100%;
    margin-right: 10%;
    &[dir='rtl'] {
        margin: 0 0 0 10%;
    }
`;

export const Number = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-right: 20px;
`;

export const NumberTitle = styled.div`
    font-size: 2rem;
    letter-spacing: 0px;
    color: #ffffff;
    margin-right: 5px;
`;

export const NumberValue = styled.div`
    font-size: 3rem;
    letter-spacing: 0px;
    font-weight: bold;
    color: #ffffff;
`;

export const EmailContainer = styled.div`
    display: flex;
    align-items: center;
    min-width: 180px;
    margin-left: auto;

    &[dir='rtl'] {
        margin-left: 0;
        margin-right: auto;
    }
`;

export const EmailLogo = styled.img`
    height: 100%;
    width: auto;
`;
export const EmailLogoWrapper = styled.div`
    height: 37px;
    width: 37px;
    margin-right: 20px;
    background: #fff;
    border-radius: 50%;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    &[dir='rtl'] {
        margin: 0 0 0 20px;
    }
`;

export const Email = styled.div<{ ref: any }>`
    position: relative;
`;

export const EmailValue = styled.div`
    color: #fff;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1.2rem;
`;

export const EmailPopup = styled.div<{ open: boolean }>`
    position: absolute;
    top: calc(100% + 10px);
    z-index: 2;
    width: 165px;
    height: 50px;
    box-shadow: 0px 3px 20px #00000029;
    background: #fff;
    opacity: 0;
    ${({ open }) => (open ? 'opacity: 1' : null)};
    transition: 0.3s ease-in-out opacity;
`;

export const UserAction = styled.div`
    padding: 17px 19px;
    height: 50px;
    display: flex;
    cursor: pointer;
    align-items: center;
    background: #fff;
    :hover {
        background: #ececec;
    }
    transition: 0.3s ease background;
`;

export const UserActionLogo = styled.img`
    height: 100%;
    width: auto;
    margin-right: 12px;
    &[dir='rtl'] {
        margin: 0 0 0 12px;
        transform: scale(-1);
    }
`;

export const UserActionTitle = styled.div`
    font-size: 1.6rem;
    letter-spacing: 0px;
    color: #4d4d4d;
`;

export const LanguageContainer = styled.div`
    margin-left: 40px;
    &[dir='rtl'] {
        margin: 0 40px 0 0;
    }
`;
