import { IBatch } from '../../interfaces/batch.interface';
import { dictionaryTypes } from '../types/dictionary.types';

export const getDictionaryRequest = () => ({
    type: dictionaryTypes.GET_DICTIONARY_REQUEST,
    request: {
        url: '/dictionaries/',
        method: 'GET'
    }
});

export const getDictionarySuccess = (data: IBatch) => ({
    type: dictionaryTypes.GET_DICTIONARY_SUCCESS,
    payload: data
});

export const getDictionaryFailure = (error: string) => ({
    type: dictionaryTypes.GET_DICTIONARY_FAILURE,
    payload: error
});
