import { takeLatest, put, call } from 'redux-saga/effects';
import * as actions from '../actions/dictionary.action';
import request from '../../helpers/request';
import { dictionaryTypes } from '../types/dictionary.types';

function* getDictionaries(action: { [key: string]: any }) {
    try {
        const { data } = yield call(request, action.request);
        yield put(actions.getDictionarySuccess(data));
    } catch (e) {
        const { data } = e.response;
        yield put(actions.getDictionaryFailure(data.detail));
    }
}

function* dictionarySaga() {
    yield takeLatest(dictionaryTypes.GET_DICTIONARY_REQUEST, getDictionaries);
}

export default dictionarySaga;
