import React, { useEffect, useState } from 'react';
import * as Styled from './style';

import { Header } from '../../shared/Header';
import { EmptyState } from './components/EmptyState';
import { Sidebar } from './components/Sidebar';
import { ViewFile } from './components/ViewFile';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { batchDataSelector, batchDocTypesSelector, batchErrorSelector } from '../../store/selectors/batch.selectors';
import {
    addDocToBatchRequest,
    batchGetDocTypesRequest,
    batchSearchFailure,
    batchSearchRequest,
    deleteDocFromBatchRequest,
    addVideoToBatchRequest,
    batchGetVideoTypesRequest,
    batchEditVideoRequest,
    deleteVideoFromBatchRequest
} from '../../store/actions/batch.action';
import { IBatch, IBatchDocument } from '../../interfaces/batch.interface';
import { DeleteModal } from './components/DeleteModal';
import { UploadModal } from './components/UploadModal';
import { VideoUrlModal } from './components/VideoUrlModal';
import { docsLoaderSelector } from '../../store/selectors/loader.selectors';
import { useDirection } from '../../hooks/useDirection';
import { LocalizedLoader } from '../../shared/Loader/LocalizedLoader';

export type UploadVideo = {
    actionType: 'POST' | 'PATCH';
    videoUrl: string;
    videoTypeId: number | null;
    videoId?: number;
};

export const Documents = () => {
    const match: any = useRouteMatch();
    const batch = useSelector(batchDataSelector);
    const error = useSelector(batchErrorSelector);
    const loading = useSelector(docsLoaderSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false);
    const [isVideoUrlModalOpen, setIsVideoUrlModalOpen] = useState<boolean>(false);
    const [docToDelete, setDocToDelete] = useState<IBatchDocument | null>(null);
    const [file, setFile] = useState(null);
    const types = useSelector(batchDocTypesSelector);

    const direction = useDirection();

    useEffect(() => {
        if (error?.length) {
            history.push('/search');
            dispatch(batchSearchFailure(''));
        }
    }, [error]);

    useEffect(() => {
        dispatch(batchGetDocTypesRequest());
        dispatch(batchGetVideoTypesRequest());
    }, []);

    useEffect(() => {
        if (match.params.id && match.params.id !== batch?.batch) {
            dispatch(batchSearchRequest(match.params.id, () => {}, false));
        }
    }, [match.params.id]);

    useEffect(() => {
        const isDocExist = batch?.documents.find((el: IBatchDocument) => el.id === parseInt(match.params.document, 10));
        if (batch && !isDocExist) {
            history.push(`/document/${match.params.id}`);
        }
    }, [match.params.document, batch]);

    const onDeleteModalClose = () => {
        setIsDeleteModalOpen(false);
        setDocToDelete(null);
    };

    const onDeleteDocHandler = () => {
        dispatch(
            deleteDocFromBatchRequest(docToDelete?.id || 0, (id) => {
                onDeleteModalClose();
            })
        );
    };

    const onDeleteModalOpen = (doc: IBatchDocument) => {
        setDocToDelete(doc);
        setIsDeleteModalOpen(true);
    };

    const docUrl = batch?.documents.find((el: IBatchDocument) => el.id === parseInt(match.params.document, 10))?.file;

    const isEmpty = !batch?.documents.length;

    const selectFile = (e: any) => {
        setFile(e.target.files[0]);
        if (e.target.files[0]) {
            setIsUploadModalOpen(true);
        }
    };

    const uploadHandler = (type: number | null) => {
        const data: FormData = new FormData();
        if (batch && type) {
            data.append('batch', batch?.batch);
            data.append('document_type', `${type}`);
            data.append('file', file || '');
            dispatch(
                addDocToBatchRequest(data, (doc: IBatchDocument) => {
                    onCloseUploadModal();
                    if (!batch.documents.length) {
                        history.push(`/document/${batch?.batch}/${doc.id}`);
                    }
                })
            );
        }
    };

    const onCloseUploadModal = () => {
        setFile(null);
        setIsUploadModalOpen(false);
    };

    const toggleVideoUrlModal = () => setIsVideoUrlModalOpen((prevIsModalOpen) => !prevIsModalOpen);

    const onUploadVideoUrlHandler = ({ actionType, videoUrl, videoTypeId, videoId }: UploadVideo) => {
        if (actionType === 'POST') dispatch(addVideoToBatchRequest(batch?.batch, videoUrl, videoTypeId));
        else dispatch(batchEditVideoRequest(videoUrl, videoId));
        toggleVideoUrlModal();
    };

    const onDeleteVideoUrlHandler = (videoId: number) => dispatch(deleteVideoFromBatchRequest(videoId));

    return (
        <Styled.Container>
            <Header />
            <DeleteModal
                doc={docToDelete}
                open={isDeleteModalOpen}
                onClose={onDeleteModalClose}
                onConfirm={onDeleteDocHandler}
            />
            <UploadModal open={isUploadModalOpen} onClose={onCloseUploadModal} onConfirm={uploadHandler} />
            <VideoUrlModal
                open={isVideoUrlModalOpen}
                onClose={toggleVideoUrlModal}
                onConfirm={onUploadVideoUrlHandler}
            />
            {loading ? (
                <Styled.LoaderWrapper>
                    <LocalizedLoader />
                </Styled.LoaderWrapper>
            ) : isEmpty ? (
                <EmptyState uploadHandler={selectFile} />
            ) : (
                <Styled.Wrapper dir={direction}>
                    <Sidebar
                        onDelete={onDeleteModalOpen}
                        uploadHandler={selectFile}
                        toggleVideoUrlModal={toggleVideoUrlModal}
                        onDeleteVideoUrlHandler={onDeleteVideoUrlHandler}
                    />
                    {match.params.document && docUrl && <ViewFile url={docUrl} />}
                </Styled.Wrapper>
            )}
        </Styled.Container>
    );
};
