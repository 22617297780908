import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { useDirection } from '../../../../hooks/useDirection';
import { Button } from '../../../../shared/Button';
import { Modal } from '../../../../shared/Modal';
import { batchLoaderSelector } from '../../../../store/selectors/loader.selectors';
import * as Styled from './style';

interface IProps {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    value: string;
}

export const ConfirmModal: FC<IProps> = ({ open, onClose, onConfirm, value }) => {
    const loader = useSelector(batchLoaderSelector);
    const { t } = useCustomTranslation('');
    const direction = useDirection();

    const confirmHandler = () => {
        onConfirm();
    };

    return (
        <Modal open={open}>
            <Styled.Content>
                <Styled.Title dir={direction}>{t('Want To Make Sure')}</Styled.Title>
                <Styled.Subtitle dir={direction}>{`${t('Is Batch Right')} ${value}`}</Styled.Subtitle>
                <Styled.Subtitle className="last" dir={direction}>
                    {t('Want To Continue')}
                </Styled.Subtitle>
                <Button
                    title={t(`Yes, I'm Sure`)}
                    onClick={confirmHandler}
                    loading={loader}
                    customStyles={{ width: 165, height: 50, marginBottom: 30 }}
                />
                <Styled.CheckButton dir={direction} onClick={onClose}>
                    {t('Check Again')}
                </Styled.CheckButton>
            </Styled.Content>
        </Modal>
    );
};
