import styled from 'styled-components';

export const LoaderContainer = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
`;

export const LocalizedApp = styled.div<{ isRtl: boolean }>`
    width: 100%;
    height: 100%;
    font-family: ${({ isRtl }) => (isRtl ? 'Rubik' : 'Open Sans')};
`;
