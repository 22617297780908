import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import CloseIcon from '../../../../assets/icons/white-cross.svg';

export const TopRow = styled.div`
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 820px) {
        margin-bottom: 45px;
    }
    @media (max-width: 500px) {
        margin-bottom: 35px;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-block-end: 15px;
`;

export const VideoUrlContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0 2.2rem;
    width: 100%;
`;

export const VideoUrl = styled.a`
    font-size: 1.5rem;
    font-weight: bold;
    color: #122a4c;
    display: block;
`;
export const ContentAddDocument = styled.label`
    background: #3b88b8;
    width: 150px;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 600;
    border-radius: 3px;
    margin-inline-start: 5px;
    margin-inline-end: 5px;
`;

export const ContentAddInput = styled.input`
    display: none;
`;

export const AddIcon = styled.img`
    width: 14px;
    height: auto;
    margin-right: 6px;
    &[dir='rtl'] {
        margin: 0 0 0 6px;
    }
`;

export const FileName = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 20px;
    max-width: 70%;
    &[dir='rtl'] {
        margin: 0 0 0 20px;
    }
`;

export const Wrapper = styled.div`
    width: 22%;
    height: 100%;
    background: #fff;
    padding: 35px 20px 20px 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    min-width: 410px;
    position: relative;
    @media (max-width: 760px) {
        width: 100%;
    }
`;

export const DocCloseButton = styled.div`
    height: 16px;
    margin-left: auto;
    background: #d8d8d8;
    border-radius: 50%;
    width: 16px;
    cursor: pointer;

    transition: 0.3s ease background;
    position: relative;

    :after {
        content: '';
        width: 12px;
        height: 12px;
        background-image: url('${CloseIcon}');
        position: absolute;
        background-size: cover;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    :hover {
        background: #b5b3b3;
    }

    &[dir='rtl'] {
        margin: 0 auto 0 0;
    }
`;

export const BackButton = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
`;
export const BackIcon = styled.img`
    width: 12px;
    height: auto;
    margin-right: 6px;
    &[dir='rtl'] {
        margin: 0 0 0 6px;
        transform: scale(-1);
    }
`;
export const BackText = styled.div`
    text-decoration: underline;
    font-size: 1.4rem;
    font-weight: 600;
    color: #122a4c;
`;

export const Number = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 8px;
    width: 100%;
`;
export const NumberLabel = styled.div`
    font-size: 2rem;
    color: #122a4c;
    margin-right: 4px;
    @media (max-width: 820px) {
        font-size: 1.6rem;
    }
    @media (max-width: 500px) {
        font-size: 1.4rem;
    }
    &[dir='rtl'] {
        margin: 0 0 0 4px;
    }
`;
export const NumberValue = styled.div`
    font-size: 2rem;
    color: #122a4c;
    font-weight: 600;
    @media (max-width: 820px) {
        font-size: 1.6rem;
    }
    @media (max-width: 500px) {
        font-size: 1.4rem;
    }
`;

export const DocumentsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const DocumentItem = styled(NavLink).attrs({
    activeClassName: 'active'
})`
    padding: 15px;

    border-left: 4px solid transparent;
    height: 90px;
    min-height: 90px;
    width: 100%;
    transition: 0.3s ease background;
    color: #4d4d4d;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 1.8rem;

    &:hover {
        background: #f9f9f9;
    }

    &.active {
        border-left: 4px solid #122a4c;
        background: #f7f7f7;
        font-weight: bold;
    }
`;

export const DocImg = styled.img`
    height: 100%;
    margin-right: 21px;
    width: auto;
    &[dir='rtl'] {
        margin: 0 0 0 21px;
    }
`;

export const Title = styled.div`
    color: #9f9f9f;
    font-size: 1.2rem;
    margin-bottom: 12px;
`;

export const Group = styled.div`
    width: 100%;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 20px;
    &:last-child {
        border: none;
        margin-bottom: 0;
    }
`;

export const LanguageContainer = styled.div`
    position: absolute;
    top: 5px;
    left: 20px;
    &[dir='rtl'] {
        left: auto;
        right: 20px;
    }
`;
