import styled from 'styled-components';

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.div`
    color: #122a4c;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 30px;
    margin-bottom: 12px;
    text-align: center;
    @media (max-width: 500px) {
        font-size: 1.9rem;
        line-height: 30px;
    }
`;

export const Subtitle = styled.div`
    color: #4d4d4d;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 25px;
    text-align: center;
    &.last {
        margin-bottom: 52px;
    }

    @media (max-width: 500px) {
        font-size: 1.1rem;
        line-height: 20px;
        max-width: none;
        width: 100%;
        padding: 0 14px;
        &.last {
            margin-bottom: 40px;
        }
    }
`;

export const ConfirmButton = styled.button`
    outline: none;
    border: none;
    font-weight: 600;
    font-size: 1.6rem;
    width: 152px;
    height: 52px;
    margin-bottom: 32px;
    background: #122a4c;
    color: #fff;
    cursor: pointer;
    @media (max-width: 500px) {
        font-size: 1.6rem;
        width: 123px;
        height: 40px;
    }
`;

export const CheckButton = styled.div`
    cursor: pointer;
    text-decoration: underline;
    color: #4d4d4d;
    font-weight: 400;
    font-size: 1.4rem;
    @media (max-width: 500px) {
        font-size: 1.2rem;
        line-height: 30px;
    }
`;

export const RadioGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > * {
        margin-bottom: 22px;
    }
    & > *:last-child {
        margin-bottom: 50px;
    }
`;

export const RadioContainer = styled.label`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 24px;
    color: #122a4c;
    font-size: 1.4rem;
    font-weight: bold;
    cursor: pointer;
    &:hover > div:before {
        transition: all 0.3s ease;
        box-shadow: inset 0 0 0 7px #fff, inset 0 0 0 22px #122a4c4d;
    }
`;

export const Radio = styled.div`
    position: relative;
    display: inline-block;
    padding-left: 1.5em;
    margin-right: 10px;
    cursor: pointer;
    line-height: 1em;
    transition: all 0.3s ease-in-out;

    height: 24px;
    width: 24px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        text-align: center;
        color: #122a4c;
        font-family: Times;
        border-radius: 50%;
        transition: all 0.3s ease;
        width: 22px;
        border: 1px solid #122a4c;
        height: 22px;
    }
    &:before {
        transition: all 0.3s ease;
    }
    &[dir='rtl'] {
        margin: 0 0 0 10px;
    }
`;

export const RadioInput = styled.input`
    display: none;

    &:checked + div:before {
        transition: all 0.3s ease;
        box-shadow: inset 0 0 0 6px #fff, inset 0 0 0 14px #122a4c;
    }
`;
