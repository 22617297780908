import styled from 'styled-components';

interface IInput {
    isPassword: boolean;
    error: string;
}

export const InputWrapper = styled.label`
    font-size: 1.4rem;
    line-height: 30px;
    color: #122a4c;
    width: 100%;
    font-weight: 600;
`;

export const InputContainer = styled.div`
    position: relative;
`;

export const InputError = styled.div`
    position: absolute;
    top: calc(100%);
    left: 0;
    color: #eb0136;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 20px;
    &[dir='rtl'] {
        left: auto;
        right: 0;
    }
`;

export const InputField = styled.input<IInput>`
    border: 1px solid #848484;
    border-radius: 3px;
    background: #ffffff;
    font-size: 1.4rem;
    padding: 11px 16px;
    color: #4d4d4d;
    width: 100%;
    font-weight: 400;
    outline: none;
    &[dir='rtl'] {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }
    ${({ error }) => (error ? 'border: 1px solid #EB0136' : null)};
    ${({ isPassword }) =>
        isPassword
            ? `
    &[dir='ltr']{
      padding-right: 50px
    }
    &[dir='rtl']{
      padding-left: 50px
    }
  `
            : null};
`;

export const InputControl = styled.img`
    position: absolute;
    width: 21px;
    height: auto;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &[dir='rtl'] {
        right: auto;
        left: 16px;
    }
`;
