import { takeLatest, put, call } from 'redux-saga/effects';
import * as actions from '../actions/auth.action';
import request from '../../helpers/request';
import { authTypes } from '../types/auth.types';
import { Messages } from '../../helpers/messages';

function* login(action: { [key: string]: any }) {
    const { history } = action.payload;
    try {
        const { data } = yield call(request, action.request);
        localStorage.setItem(process.env.REACT_APP_TOKEN_NAME || '', data.token);
        localStorage.setItem('pana_email', action.request.data.username);
        yield put(actions.loginSuccess(data.token));
        history.push('/');
    } catch (e) {
        yield put(actions.loginFailure(Messages.LOGIN_ERROR));
    }
}

function* authSaga() {
    yield takeLatest(authTypes.LOGIN_REQUEST, login);
}

export default authSaga;
