import styled from 'styled-components';

export const ContentWrapper = styled.div`
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

export const Content = styled.div`
    width: 100%;
    max-width: 750px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const ContentTitle = styled.div`
    color: #9b9b9b;
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 85px;
`;

export const ContentSubTitle = styled.div`
    color: #9b9b9b;
    font-weight: normal;
    font-size: 1.6rem;
    margin-bottom: 60px;
`;

export const ContentImage = styled.img`
    width: 343px;
    height: auto;
    margin-bottom: 90px;
`;

export const ContentAddDocument = styled.label`
    background: #3b88b8;
    width: 195px;
    cursor: pointer;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 600;
`;

export const ContentAddInput = styled.input`
    display: none;
`;

export const AddIcon = styled.img`
    width: 14px;
    height: auto;
    margin-right: 6px;
    &[dir='rtl'] {
        margin: 0 0 0 6px;
    }
`;

export const FixedContainer = styled.div`
    top: 35px;
    left: 20px;
    position: absolute;
    &[dir='rtl'] {
        left: auto;
        right: 20px;
    }
`;

export const BackButton = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-bottom: 35px;
`;

export const BackIcon = styled.img`
    width: 12px;
    height: auto;
    margin-right: 6px;
    &[dir='rtl'] {
        margin: 0 0 0 6px;
        transform: scale(-1);
    }
`;

export const BackText = styled.div`
    text-decoration: underline;
    font-size: 1.4rem;
    color: #122a4c;
    font-weight: 600;
`;

export const Number = styled.div`
    display: flex;
    align-items: flex-end;
    margin-bottom: 50px;
`;
export const NumberLabel = styled.div`
    font-size: 2rem;
    color: #122a4c;
    margin-right: 4px;
    &[dir='rtl'] {
        margin: 0 0 0 4px;
    }
`;
export const NumberValue = styled.div`
    font-size: 2rem;
    color: #122a4c;
    font-weight: 600;
`;

export const LanguageContainer = styled.div`
    position: absolute;
    top: 5px;
    left: 20px;
    &[dir='rtl'] {
        left: auto;
        right: 20px;
    }
`;
