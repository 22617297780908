import { IBatch, IDocType } from '../../interfaces/batch.interface';
import { IDictionary } from '../../interfaces/dictionary.interface';
import { dictionaryTypes } from '../types/dictionary.types';

interface IInitialState {
    dictionaries: IDictionary[];
    error: string;
}

const initialState: IInitialState = {
    dictionaries: [],
    error: ''
};

const dictionaryReducer = (state = initialState, { payload, type }: any) => {
    switch (type) {
        case dictionaryTypes.GET_DICTIONARY_REQUEST: {
            return {
                ...state,
                error: ''
            };
        }

        case dictionaryTypes.GET_DICTIONARY_SUCCESS: {
            return {
                ...state,
                error: '',
                dictionaries: payload
            };
        }

        case dictionaryTypes.GET_DICTIONARY_FAILURE: {
            return {
                ...state,
                error: payload,
                dictionaries: []
            };
        }

        default:
            return state;
    }
};

export default dictionaryReducer;
