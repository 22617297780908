import React, { useEffect, useState } from 'react';
import * as Styled from './style';
import Logo from '../../assets/icons/logo.svg';
import { Input } from '../../shared/Input';
import { Button } from '../../shared/Button';
import { Field, Form, Formik } from 'formik';
import { schema } from './schema';
import { loginRequest } from '../../store/actions/auth.action';
import { useDispatch, useSelector } from 'react-redux';
import { authLoaderSelector } from '../../store/selectors/loader.selectors';
import { authErrorSelector } from '../../store/selectors/auth.selectors';
import { useHistory } from 'react-router-dom';
import { useAuthorization } from '../../hooks/useAuthorization';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import { useDirection } from '../../hooks/useDirection';
import { LanguageManager } from '../../shared/LanguageManager';

export const Login = () => {
    const authLoader = useSelector(authLoaderSelector);
    const dispatch = useDispatch();
    const error = useSelector(authErrorSelector);
    const history = useHistory();
    const { t } = useCustomTranslation('');

    const direction = useDirection();

    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (error && error.length) {
            setIsError(true);
            setTimeout(() => setIsError(false), 3000);
        }
    }, [error]);

    useAuthorization();

    return (
        <Styled.Wrapper>
            <Styled.Container>
                <Styled.LanguageContainer dir={direction}>
                    <LanguageManager />
                </Styled.LanguageContainer>
                <Styled.Logo src={Logo} alt="" />
                <Styled.Texts>
                    <Styled.Title dir={direction}>{t('Log In')}</Styled.Title>
                    <Styled.Subtitle dir={direction}>{t('Enter Details')}</Styled.Subtitle>
                </Styled.Texts>
                <Formik
                    initialValues={{
                        username: '',
                        password: ''
                    }}
                    onSubmit={(values) => {
                        dispatch(loginRequest(values, history));
                    }}
                    validateOnBlur={false}
                    validationSchema={schema}
                >
                    {({ errors, isValid }) => (
                        <Form>
                            <Styled.FormWrapper>
                                <Styled.ErrorBlock>{isError ? t(error) || '' : ''}</Styled.ErrorBlock>
                                <Styled.Fields>
                                    <Field
                                        name="username"
                                        component={Input}
                                        type="text"
                                        placeholder={`${t('Email address')}...`}
                                        label={t('Email address')}
                                        error={errors.username && t(errors.username)}
                                        dir={direction}
                                    />
                                    <Field
                                        name="password"
                                        component={Input}
                                        type="password"
                                        placeholder={`${t('Enter Password')}...`}
                                        label={t('Password')}
                                        error={errors.password && t(errors.password)}
                                        dir={direction}
                                    />
                                </Styled.Fields>
                                <Button disabled={!isValid} title={t('Log In')} loading={authLoader} />
                            </Styled.FormWrapper>
                        </Form>
                    )}
                </Formik>
            </Styled.Container>
        </Styled.Wrapper>
    );
};
