import React, { FC, ReactElement, useEffect } from 'react';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getDictionaryRequest } from '../../store/actions/dictionary.action';
import { dictionaryDataSelector } from '../../store/selectors/dictionary.selector';
import i18n from 'i18next';
import { setLocale } from '../../helpers/setLocale';
import * as Styled from './style';
import { LocalizedLoader } from '../Loader/LocalizedLoader';
import { LocalizationWrapper } from './LocalizationWrapper';

interface IProps {
    children: ReactElement;
}

export const Localization: FC<IProps> = ({ children }) => {
    const dispatch = useDispatch();
    const dictionaries = useSelector(dictionaryDataSelector);
    const lang_code = localStorage.getItem('lang_code') || 'en';

    useEffect(() => {
        dispatch(getDictionaryRequest());
    }, []);

    i18n.use(initReactI18next).init({
        resources: setLocale(dictionaries),
        lng: lang_code,
        interpolation: {
            escapeValue: false
        }
    });

    return dictionaries.length ? (
        <I18nextProvider i18n={i18n}>
            <LocalizationWrapper>{children}</LocalizationWrapper>
        </I18nextProvider>
    ) : (
        <Styled.LoaderContainer>
            <LocalizedLoader />
        </Styled.LoaderContainer>
    );
};
