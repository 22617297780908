import React, { FC, MouseEvent } from 'react';
import * as Styled from './style';

interface IProps {
    title: string;
    color?: string;
    loading?: boolean;
    customStyles?: React.CSSProperties;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
}

export const Button: FC<IProps> = ({ title, color, loading = false, customStyles, onClick, disabled = false }) => {
    return (
        <Styled.ButtonComponent
            onClick={disabled ? () => {} : onClick}
            color={color || 'background'}
            style={customStyles}
            isDisable={disabled || loading}
        >
            {loading ? <Styled.ButtonLoader /> : title}
        </Styled.ButtonComponent>
    );
};
