import React from 'react';
import * as Styled from './style';
import LoaderIcon from '../../assets/icons/loader-icon.svg';
import useCustomTranslation from '../../hooks/useCustomTranslation';

export const LocalizedLoader = () => {
    const { t } = useCustomTranslation('');

    return (
        <Styled.Container>
            <Styled.IconContainer>
                <Styled.Icon src={LoaderIcon} alt="" />
            </Styled.IconContainer>
            <Styled.Title>{t('Please wait')}</Styled.Title>
        </Styled.Container>
    );
};
