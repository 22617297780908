import React, { ChangeEvent, FC } from 'react';
import * as Styled from './style';
import Empty from '../../../../assets/icons/empty_batch.svg';
import AddIcon from '../../../../assets/icons/plus.svg';
import Arrow from '../../../../assets/icons/left_arrow.svg';
import { useSelector } from 'react-redux';
import { batchDataSelector } from '../../../../store/selectors/batch.selectors';
import { useHistory } from 'react-router-dom';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { useDirection } from '../../../../hooks/useDirection';

interface IProps {
    uploadHandler: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const EmptyState: FC<IProps> = ({ uploadHandler }) => {
    const batch = useSelector(batchDataSelector);
    const history = useHistory();

    const { t } = useCustomTranslation('');
    const direction = useDirection();

    const backHandler = () => history.push('/search');

    return (
        <Styled.ContentWrapper>
            <Styled.FixedContainer dir={direction}>
                <Styled.BackButton onClick={backHandler}>
                    <Styled.BackIcon dir={direction} src={Arrow} alt="" />
                    <Styled.BackText dir={direction}>{t('New Batch Number')}</Styled.BackText>
                </Styled.BackButton>
                <Styled.Number dir={direction}>
                    <Styled.NumberLabel dir={direction}>{t('Batch Number')}:</Styled.NumberLabel>
                    <Styled.NumberValue>{batch?.batch || ''}</Styled.NumberValue>
                </Styled.Number>
            </Styled.FixedContainer>
            <Styled.Content>
                <Styled.ContentTitle dir={direction}>{t('Empty Docs State')}</Styled.ContentTitle>
                <Styled.ContentImage alt="" src={Empty} />
                <Styled.ContentAddDocument>
                    <Styled.ContentAddInput onChange={uploadHandler} type="file" accept="application/pdf" />
                    <Styled.AddIcon alt="" src={AddIcon} />
                    {t('Upload Document')}
                </Styled.ContentAddDocument>
            </Styled.Content>
        </Styled.ContentWrapper>
    );
};
