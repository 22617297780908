import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const loaderAnimation = keyframes`
  0% { transform: rotate(10deg) }
  50% { transform: rotate(150deg) }
  100% { transform: rotate(10deg) }
`;

export const Icon = styled.img`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${loaderAnimation} 3s infinite cubic-bezier(0.65, 0.01, 0.27, 1.01);
`;

export const IconContainer = styled.div`
    width: 75px;
    height: 75px;
`;

export const Title = styled.div`
    font-size: 1.4rem;
    color: #4d4d4d;
    text-align: center;
`;
