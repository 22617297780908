import React, { FC } from 'react';
import * as Styled from './style';
import LoaderIcon from '../../assets/icons/loader-icon.svg';

interface IProps {
    title?: string;
    dir?: string;
}

export const Loader: FC<IProps> = ({ title = 'Please wait...', dir = 'ltr' }) => {
    return (
        <Styled.Container dir={dir}>
            <Styled.IconContainer>
                <Styled.Icon src={LoaderIcon} alt="" />
            </Styled.IconContainer>
            <Styled.Title>{title}</Styled.Title>
        </Styled.Container>
    );
};
