import { authTypes } from '../types/auth.types';
import { batchTypes } from '../types/batch.types';

interface IInitialState {
    auth: boolean;
    batch: boolean;
    types: boolean;
}

const initialState: IInitialState = {
    auth: false,
    batch: false,
    types: false
};

const loaderReducer = (state = initialState, action: any) => {
    switch (action.type) {
        // AUTH
        case authTypes.LOGIN_REQUEST: {
            return {
                ...state,
                auth: true
            };
        }
        case authTypes.LOGIN_SUCCESS:
        case authTypes.LOGIN_FAILURE: {
            return {
                ...state,
                auth: false
            };
        }
        // BATCH
        case batchTypes.ADD_DOC_TO_BATCH_REQUEST:
        case batchTypes.BATCH_DELETE_DOC_REQUEST:
        case batchTypes.ADD_VIDEO_TO_BATCH_REQUEST:
        case batchTypes.BATCH_EDIT_VIDEO_REQUEST:
        case batchTypes.BATCH_DELETE_VIDEO_REQUEST:
        case batchTypes.BATCH_SEARCH_REQUEST: {
            return {
                ...state,
                batch: true
            };
        }

        case batchTypes.ADD_DOC_TO_BATCH_SUCCESS:
        case batchTypes.ADD_DOC_TO_BATCH_FAILURE:
        case batchTypes.BATCH_DELETE_DOC_SUCCESS:
        case batchTypes.BATCH_DELETE_DOC_FAILURE:
        case batchTypes.BATCH_EDIT_VIDEO_SUCCESS:
        case batchTypes.BATCH_EDIT_VIDEO_FAILURE:
        case batchTypes.BATCH_DELETE_VIDEO_FAILURE:
        case batchTypes.BATCH_DELETE_VIDEO_SUCCESS:
        case batchTypes.ADD_VIDEO_TO_BATCH_FAILURE:
        case batchTypes.ADD_VIDEO_TO_BATCH_SUCCESS:
        case batchTypes.BATCH_SEARCH_SUCCESS:
        case batchTypes.BATCH_SEARCH_FAILURE: {
            return {
                ...state,
                batch: false
            };
        }

        // TYPES
        case batchTypes.BATCH_GET_DOCTYPES_REQUEST: {
            return {
                ...state,
                types: true
            };
        }
        case batchTypes.BATCH_GET_DOCTYPES_SUCCESS:
        case batchTypes.BATCH_GET_DOCTYPES_FAILURE: {
            return {
                ...state,
                types: false
            };
        }
        default:
            return state;
    }
};

export default loaderReducer;
