import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { useDirection } from '../../../../hooks/useDirection';
import { IDocType } from '../../../../interfaces/batch.interface';
import { Button } from '../../../../shared/Button';
import { Modal } from '../../../../shared/Modal';
import { batchDocTypesSelector } from '../../../../store/selectors/batch.selectors';
import { batchLoaderSelector } from '../../../../store/selectors/loader.selectors';
import * as Styled from './style';

interface IProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (type: number | null) => void;
}

export const UploadModal: FC<IProps> = ({ open, onClose, onConfirm }) => {
    const types = useSelector(batchDocTypesSelector);
    const [selectedType, setSelectedType] = useState<null | number>(null);
    const loader = useSelector(batchLoaderSelector);

    const { t } = useCustomTranslation('');
    const direction = useDirection();

    useEffect(() => {
        setSelectedType(types[0] ? types[0].id : null);
    }, [types.length]);

    const confirmHandler = () => {
        onConfirm(selectedType);
    };

    useEffect(() => {
        if (!open) {
            setSelectedType(null);
        } else {
            setSelectedType(types[0] ? types[0].id : null);
        }
    }, [open]);

    return (
        <Modal open={open} staticBehavior>
            <Styled.Content>
                <Styled.Title dir={direction}>{t('Select Document Type')}</Styled.Title>
                <Styled.Subtitle dir={direction}>{t('Continue Upload')}</Styled.Subtitle>
                <Styled.Subtitle className="last" dir={direction}>
                    {t('Please Upload')}
                </Styled.Subtitle>
                <Styled.RadioGroup dir={direction}>
                    {types.map((docType: IDocType) => (
                        <Styled.RadioContainer dir={direction} key={docType.id}>
                            <Styled.RadioInput
                                type="radio"
                                checked={docType.id === selectedType}
                                onChange={() => {
                                    setSelectedType(docType.id);
                                }}
                            />
                            <Styled.Radio dir={direction} />
                            {t(docType.label || '')}
                        </Styled.RadioContainer>
                    ))}
                </Styled.RadioGroup>
                <Button
                    title={t('Upload')}
                    onClick={confirmHandler}
                    loading={loader}
                    customStyles={{ width: 165, height: 50, marginBottom: 30 }}
                />
                <Styled.CheckButton onClick={onClose} dir={direction}>
                    {t('Cancel')}
                </Styled.CheckButton>
            </Styled.Content>
        </Modal>
    );
};
