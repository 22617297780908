import { authTypes } from '../types/auth.types';

interface ILoginData {
    username: string;
    password: string;
}

export const loginRequest = (data: ILoginData, history: any) => ({
    type: authTypes.LOGIN_REQUEST,
    payload: { history },
    request: {
        url: '/api-token-auth/',
        method: 'POST',
        data
    }
});

export const loginSuccess = (token: string) => ({
    type: authTypes.LOGIN_SUCCESS,
    payload: token
});

export const loginFailure = (error: string) => ({
    type: authTypes.LOGIN_FAILURE,
    payload: error
});
