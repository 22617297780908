import { createSelector } from 'reselect';

export const batchSelector = (state: any) => state.batchReducer;

export const batchErrorSelector = createSelector(batchSelector, (data) => data.error || '');

export const batchDataSelector = createSelector(batchSelector, (data) => data.batch || null);

export const batchDocTypesSelector = createSelector(batchSelector, (data) => data.docTypes || []);

export const batchesSelector = createSelector(batchSelector, (data) => data.batches || []);

export const batchVideoTypesSelector = createSelector(batchSelector, (data) => data.videoTypes || []);
