import React, { FC, useState, useRef, useLayoutEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useCustomTranslation from '../../../../hooks/useCustomTranslation';
import { useDirection } from '../../../../hooks/useDirection';
import { Button } from '../../../../shared/Button';
import { Modal } from '../../../../shared/Modal';
import {
    batchDataSelector,
    batchVideoTypesSelector,
    batchErrorSelector
} from '../../../../store/selectors/batch.selectors';
import { batchLoaderSelector } from '../../../../store/selectors/loader.selectors';
import { ReactComponent as CloseCross } from '../../../../assets/icons/white-cross.svg';
import { IVideoType, IBatchVideo } from '../../../../interfaces/batch.interface';
import { URL_REGEX } from './urlRegex';
import { UploadVideo } from '../../Documents';
import * as Styled from './style';

interface IProps {
    open: boolean;
    onClose: () => void;
    onConfirm: (video: UploadVideo) => void;
}

export const VideoUrlModal: FC<IProps> = ({ open, onClose, onConfirm }) => {
    const loader = useSelector(batchLoaderSelector);
    const batch = useSelector(batchDataSelector);
    const types = useSelector(batchVideoTypesSelector);
    const error = useSelector(batchErrorSelector);

    const [videoUrl, setVideoUrl] = useState<string>('');
    const [selectedVideoType, setSelectedVideoType] = useState<number | null>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const { t } = useCustomTranslation('');
    const direction = useDirection();

    useLayoutEffect(() => {
        inputRef.current?.focus();
    }, [open]);

    const onHandleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const videoURL = e.target.value.trim();
        setVideoUrl(videoURL);
    };

    const confirmHandler = useCallback(() => {
        const batchVideo: IBatchVideo | undefined = batch?.videos?.find(
            (video: IBatchVideo) => video.video_type.id === selectedVideoType
        );

        const video: UploadVideo = {
            actionType: batchVideo ? 'PATCH' : 'POST',
            videoUrl,
            videoTypeId: batchVideo ? null : selectedVideoType,
            videoId: batchVideo?.id
        };

        onConfirm(video);
        setVideoUrl('');
        setSelectedVideoType(null);
    }, [batch, videoUrl, selectedVideoType, onConfirm]);

    const isUrlNotValid = (): boolean => {
        const urlRegex = new RegExp(URL_REGEX);
        return !urlRegex.test(videoUrl.trim());
    };

    const isBtnDisabled = isUrlNotValid() || !selectedVideoType;

    const isRadioBtnsDisabled = isUrlNotValid();

    return (
        <Modal open={open}>
            <Styled.CloseButton onClick={onClose}>
                <CloseCross />
            </Styled.CloseButton>
            <Styled.Content>
                <Styled.Title dir={direction}>{t('Add Video URL')}</Styled.Title>
                <Styled.Subtitle dir={direction}>
                    {t('Please Enter a URL for the video that will be represented for this batch number')}{' '}
                </Styled.Subtitle>
                <Styled.InputWrapper dir={direction}>
                    <Styled.LinkInput
                        ref={inputRef}
                        dir={direction}
                        value={videoUrl}
                        type="text"
                        placeholder={t('Enter a valid video URL')}
                        onChange={onHandleInputChange}
                    />
                </Styled.InputWrapper>
                {error && (
                    <Styled.ErrorMessage dir={direction}>
                        {t('Could not upload/edit video, try again.')}
                    </Styled.ErrorMessage>
                )}
                <Styled.RadioGroup dir={direction} isDisabled={isRadioBtnsDisabled}>
                    {types.map((type: IVideoType) => (
                        <Styled.RadioContainer dir={direction} key={type.id}>
                            <Styled.RadioInput
                                disabled={isRadioBtnsDisabled}
                                type="radio"
                                checked={type.id === selectedVideoType}
                                onChange={() => setSelectedVideoType(type.id)}
                            />
                            <Styled.Radio dir={direction} />
                            {t(type.label || '')}
                        </Styled.RadioContainer>
                    ))}
                </Styled.RadioGroup>
                <Styled.ButtonsContainer>
                    <Button
                        disabled={isBtnDisabled}
                        title={t(`Submit`)}
                        onClick={confirmHandler}
                        loading={loader}
                        customStyles={{
                            width: 165,
                            height: 50,
                            marginBottom: 30,
                            marginInlineStart: 10
                        }}
                    />
                    <Button
                        title={t(`Cancel`)}
                        onClick={() => onClose()}
                        customStyles={{
                            width: 165,
                            height: 50,
                            marginBottom: 30,
                            marginInlineStart: 10
                        }}
                    />
                </Styled.ButtonsContainer>
            </Styled.Content>
        </Modal>
    );
};
