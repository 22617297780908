import React, { FC } from 'react';
import * as Styled from './style';

interface IProps {
    open: boolean;
    children: React.ReactNode;
    staticBehavior?: boolean;
}

export const Modal: FC<IProps> = ({ open, children, staticBehavior }) => {
    return (
        <Styled.Container open={open}>
            <Styled.Background open={open} staticBehavior={!!staticBehavior} />
            <Styled.Content open={open} staticBehavior={!!staticBehavior}>
                {children}
            </Styled.Content>
        </Styled.Container>
    );
};
