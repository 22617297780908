import styled from 'styled-components';

export const Container = styled.div<{ open: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    visibility: hidden;
    ${({ open }) => (open ? 'visibility: visible' : null)}
`;

export const Background = styled.div<{
    open: boolean;
    staticBehavior: boolean;
}>`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0;
    z-index: -1;
    ${({ open }) => (open ? 'opacity: 0.8' : null)};
    ${({ staticBehavior }) => (staticBehavior ? null : 'transition: 0.3s ease-in-out opacity;')}
`;

export const Content = styled.div<{ open: boolean; staticBehavior: boolean }>`
    width: calc(100% / 3);
    min-width: 450px;
    padding: 34px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #fff;
    opacity: 0;
    ${({ open }) => (open ? 'opacity: 1' : null)};
    ${({ staticBehavior }) => (staticBehavior ? null : 'transition: 0.3s ease-in-out opacity;')}

    @media(max-width: 500px) {
        min-width: auto;
        width: 100%;
        margin: 30px;
        padding: 45px 25px;
    }
`;
