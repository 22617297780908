import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/icons/logo-light.svg';
import User from '../../assets/icons/user.svg';
import Logout from '../../assets/icons/logout.svg';
import { closeOnBlur } from '../../helpers/closeOnBlur';
import * as Styled from './style';
import { useHistory } from 'react-router-dom';
import { LanguageManager } from '../../shared/LanguageManager';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import { useDirection } from '../../hooks/useDirection';

export const Header = () => {
    const [open, setOpen] = useState<boolean>(false);
    const popupRef = useRef<HTMLDivElement>();
    const history = useHistory();

    const { t } = useCustomTranslation('');
    const direction = useDirection();

    const logoutHandler = () => {
        localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME || '');
        history.push('/auth');
    };

    useEffect(() => {
        closeOnBlur(popupRef, setOpen);
    }, []);

    return (
        <Styled.Header dir={direction}>
            <Styled.Logo dir={direction} src={Logo} alt="" />
            <Styled.EmailContainer dir={direction}>
                <Styled.EmailLogoWrapper dir={direction}>
                    <Styled.EmailLogo alt="" src={User} />
                </Styled.EmailLogoWrapper>
                <Styled.Email ref={popupRef}>
                    <Styled.EmailValue onClick={() => setOpen(!open)}>
                        {localStorage.getItem('pana_email')}
                    </Styled.EmailValue>
                    <Styled.EmailPopup open={open}>
                        <Styled.UserAction dir={direction} onClick={logoutHandler}>
                            <Styled.UserActionLogo dir={direction} alt="" src={Logout} />
                            <Styled.UserActionTitle dir={direction}>{t('Log out')}</Styled.UserActionTitle>
                        </Styled.UserAction>
                    </Styled.EmailPopup>
                </Styled.Email>
                <Styled.LanguageContainer dir={direction}>
                    <LanguageManager type="white" />
                </Styled.LanguageContainer>
            </Styled.EmailContainer>
        </Styled.Header>
    );
};
