import styled from 'styled-components';

export const Content = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Title = styled.div`
    color: #122a4c;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 30px;
    margin-bottom: 12px;
    text-align: center;
    @media (max-width: 500px) {
        font-size: 1.9rem;
        line-height: 30px;
    }
`;

export const Subtitle = styled.div`
    color: #4d4d4d;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 25px;
    text-align: center;
    &.last {
        margin-bottom: 52px;
    }

    @media (max-width: 500px) {
        font-size: 1.1rem;
        line-height: 20px;
        max-width: none;
        width: 100%;
        padding: 0 14px;
        &.last {
            margin-bottom: 40px;
        }
    }
`;

export const ConfirmButton = styled.button`
    outline: none;
    border: none;
    font-weight: 600;
    font-size: 1.6rem;
    width: 152px;
    height: 52px;
    margin-bottom: 32px;
    background: #122a4c;
    color: #fff;
    cursor: pointer;
    @media (max-width: 500px) {
        font-size: 1.6rem;
        width: 123px;
        height: 40px;
    }
`;

export const CheckButton = styled.div`
    cursor: pointer;
    text-decoration: underline;
    color: #4d4d4d;
    font-weight: 400;
    font-size: 1.4rem;
    @media (max-width: 500px) {
        font-size: 1.2rem;
        line-height: 30px;
    }
`;
