import { authTypes } from '../types/auth.types';

const initialState = {
    error: null
};

const authReducer = (state = initialState, { payload, type }: any): typeof initialState => {
    switch (type) {
        case authTypes.LOGIN_REQUEST: {
            return {
                ...state,
                error: null
            };
        }
        case authTypes.LOGIN_FAILURE: {
            return {
                ...state,
                error: payload
            };
        }

        default:
            return state;
    }
};

export default authReducer;
