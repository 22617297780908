import React, { FC, ReactNode } from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';

interface IProps {
    component: ReactNode;
    exact?: boolean;
    path: string;
}

export const PrivateRoute: FC<IProps> = (props) => {
    const isAuthenticated = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME || '') || false;
    const { component, ...rest }: IProps = props;
    return (
        <Route
            {...rest}
            render={(routeProps: RouteComponentProps) =>
                !isAuthenticated ? (
                    <Redirect to="/login" />
                ) : (
                    // @ts-ignore
                    <props.component {...routeProps} {...rest} />
                )
            }
        />
    );
};
