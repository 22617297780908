import styled from 'styled-components';

const detectTypeColor = (type: string) => {
    switch (type) {
        case 'white':
            return '#fff';
        default:
            return '#122a4c';
    }
};

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const Separator = styled.div<{ type: string }>`
    width: 1px;
    height: 10px;
    background: ${({ type }) => detectTypeColor(type)};
    margin: 0 10px;
`;

export const Value = styled.div<{ type: string }>`
    color: ${({ type }) => detectTypeColor(type)};
    position: relative;
    cursor: pointer;
    font-size: 1.2rem;

    &.active {
        font-weight: 800;
    }

    &:last-child {
        margin-right: 0;
    }
`;
