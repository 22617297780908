import React from 'react';
import { store } from './store';
import { Provider } from 'react-redux';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { PrivateRoute } from './navigation/PrivateRoute';
import { routes } from './navigation/index';
import { Localization } from './shared/Localization';
import { unAuthorizedInterceptor } from './helpers/request';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
export const App = () => (
    <Provider store={store}>
        <Localization>
            <BrowserRouter>
                <Switch>
                    {routes.map(({ name, isPrivate, ...rest }) =>
                        isPrivate ? <PrivateRoute {...rest} key={rest.path} /> : <Route {...rest} key={rest.path} />
                    )}
                    <Redirect from="/" to="/search" />
                </Switch>
            </BrowserRouter>
        </Localization>
    </Provider>
);

unAuthorizedInterceptor();
