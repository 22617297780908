import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useDirection = () => {
    const { i18n } = useTranslation();
    const [direction, setDirection] = useState(i18n.language || 'en');

    useEffect(() => {
        setDirection(i18n.language);
    }, [i18n.language]);

    return direction === 'he' ? 'rtl' : 'ltr';
};
