export enum batchTypes {
    BATCH_SEARCH_REQUEST = 'BATCH_SEARCH_REQUEST',
    BATCH_SEARCH_SUCCESS = 'BATCH_SEARCH_SUCCESS',
    BATCH_SEARCH_FAILURE = 'BATCH_SEARCH_FAILURE',
    BATCH_SEARCH_RESET = 'BATCH_SEARCH_RESET',

    BATCH_CREATE_REQUEST = 'BATCH_CREATE_REQUEST',
    BATCH_CREATE_SUCCESS = 'BATCH_CREATE_SUCCESS',
    BATCH_CREATE_FAILURE = 'BATCH_CREATE_FAILURE',

    ADD_DOC_TO_BATCH_REQUEST = 'ADD_DOC_TO_BATCH_REQUEST',
    ADD_DOC_TO_BATCH_SUCCESS = 'ADD_DOC_TO_BATCH_SUCCESS',
    ADD_DOC_TO_BATCH_FAILURE = 'ADD_DOC_TO_BATCH_FAILURE',

    BATCH_GET_DOCTYPES_REQUEST = 'BATCH_GET_DOCTYPES_REQUEST',
    BATCH_GET_DOCTYPES_SUCCESS = 'BATCH_GET_DOCTYPES_SUCCESS',
    BATCH_GET_DOCTYPES_FAILURE = 'BATCH_GET_DOCTYPES_FAILURE',

    BATCH_GET_VIDEO_TYPES_REQUEST = 'BATCH_GET_VIDEO_TYPES_REQUEST',
    BATCH_GET_VIDEO_TYPES_SUCCESS = 'BATCH_GET_VIDEO_TYPES_SUCCESS',
    BATCH_GET_VIDEO_TYPES_FAILURE = 'BATCH_GET_VIDEO_TYPES_FAILURE',

    ADD_VIDEO_TO_BATCH_REQUEST = 'ADD_VIDEO_TO_BATCH_REQUEST',
    ADD_VIDEO_TO_BATCH_SUCCESS = 'ADD_VIDEO_TO_BATCH_SUCCESS',
    ADD_VIDEO_TO_BATCH_FAILURE = 'ADD_VIDEO_TO_BATCH_FAILURE',

    BATCH_EDIT_VIDEO_REQUEST = 'BATCH_EDIT_VIDEO_REQUEST',
    BATCH_EDIT_VIDEO_SUCCESS = 'BATCH_EDIT_VIDEO_SUCCESS',
    BATCH_EDIT_VIDEO_FAILURE = 'BATCH_EDIT_VIDEO_FAILURE',

    BATCH_DELETE_VIDEO_REQUEST = 'BATCH_DELETE_VIDEO_REQUEST',
    BATCH_DELETE_VIDEO_SUCCESS = 'BATCH_DELETE_VIDEO_SUCCESS',
    BATCH_DELETE_VIDEO_FAILURE = 'BATCH_DELETE_VIDEO_FAILURE',

    BATCH_DELETE_DOC_REQUEST = 'BATCH_DELETE_DOC_REQUEST',
    BATCH_DELETE_DOC_SUCCESS = 'BATCH_DELETE_DOC_SUCCESS',
    BATCH_DELETE_DOC_FAILURE = 'BATCH_DELETE_DOC_FAILURE',

    GET_BATCHES_REQUEST = 'GET_BATCHES_REQUEST',
    GET_BATCHES_SUCCESS = 'GET_BATCHES_SUCCESS',
    GET_BATCHES_FAILURE = 'GET_BATCHES_FAILURE'
}
