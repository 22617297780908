import React, { FC, ReactElement } from 'react';
import { useDirection } from '../../hooks/useDirection';
import * as Styled from './style';

interface IProps {
    children: ReactElement;
}

export const LocalizationWrapper: FC<IProps> = ({ children }) => {
    const direction = useDirection();
    return <Styled.LocalizedApp isRtl={direction === 'rtl'}>{children}</Styled.LocalizedApp>;
};
