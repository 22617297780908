import React, { ChangeEvent, useEffect, useState } from 'react';
import * as Styled from './style';
import Logo from '../../assets/icons/logo.svg';
import { ConfirmModal } from './components/ConfirmModal/ConfirmModal';
import { useDispatch, useSelector } from 'react-redux';
import { batchSearchRequest, getBatchesRequest, batchSearchReset } from '../../store/actions/batch.action';
import { batchErrorSelector, batchesSelector } from '../../store/selectors/batch.selectors';
import { useHistory } from 'react-router-dom';
import useCustomTranslation from '../../hooks/useCustomTranslation';
import { useDirection } from '../../hooks/useDirection';
import { IBatch } from '../../interfaces/batch.interface';
import { Header } from '../../shared/Header';

export const Search = () => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const error = useSelector(batchErrorSelector);
    const { t } = useCustomTranslation('');
    const direction = useDirection();
    const batches = useSelector(batchesSelector);
    const history = useHistory();

    useEffect(() => {
        dispatch(getBatchesRequest());
        dispatch(batchSearchReset());
    }, []);

    useEffect(() => {
        if (error.length) {
            setOpen(false);
        }
    }, [error]);

    const isValid = /^.*$/.test(searchValue);
    const disabled = !isValid;

    const submitHandler = () => {
        setOpen(true);
    };

    const confirmHandler = () => {
        dispatch(
            batchSearchRequest(
                searchValue,
                (id) => {
                    history.push(`/document/${id}`);
                },
                true
            )
        );
    };

    const onSelectBatch = (number: string) => {
        history.replace(`/document/${number}`);
    };

    return (
        <>
            <Header />
            <Styled.Container>
                <ConfirmModal
                    open={open}
                    onClose={() => setOpen(false)}
                    onConfirm={confirmHandler}
                    value={searchValue}
                />
                <Styled.ContentWrapper>
                    <Styled.Wrapper dir={direction}>
                        <Styled.List>
                            <Styled.ListTitle dir={direction}>{t('All Batch Numbers')}</Styled.ListTitle>
                            <Styled.ListItems>
                                {batches.map((item: IBatch) => (
                                    <Styled.ListItem key={item.batch} onClick={() => onSelectBatch(item.batch)}>
                                        {item.batch}
                                    </Styled.ListItem>
                                ))}
                            </Styled.ListItems>
                        </Styled.List>
                        <Styled.Content>
                            <Styled.Logo src={Logo} alt="" />
                            <Styled.Title dir={direction}>{t('Enter Batch Number')}</Styled.Title>
                            <Styled.Subtitle dir={direction}>{t('Enter Batch Subtitle')}</Styled.Subtitle>
                            <Styled.SearchContainer error={error && t('Invalid Batch Number')} dir={direction}>
                                <Styled.SearchField
                                    dir={direction}
                                    placeholder={t('Enter Batch Placeholder')}
                                    value={searchValue}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
                                    error={error && t('Invalid Batch Number')}
                                />
                                <Styled.SearchButton disabled={disabled} onClick={submitHandler}>
                                    {t('Submit')}
                                </Styled.SearchButton>
                            </Styled.SearchContainer>
                        </Styled.Content>
                    </Styled.Wrapper>
                </Styled.ContentWrapper>
                <Styled.ImageBlock />
            </Styled.Container>
        </>
    );
};
