import { IDictionary } from '../interfaces/dictionary.interface';

export const setLocale = (dictionaries: IDictionary[]) => {
    const langs = dictionaries.reduce((obj: any, phrase: IDictionary) => {
        const lang = obj[phrase.language_code] || { translation: {} };

        const sentenses = {
            ...lang.translation,
            [`${phrase.sentence}`]: phrase.localized_sentence
        };
        lang.translation = sentenses;

        return { ...obj, [phrase.language_code]: lang };
    }, {});
    return langs;
};
