import axios, { AxiosInstance } from 'axios';
import { createBrowserHistory } from 'history';
import { API_URL } from './constants';

const apiCall = axios.create({
    baseURL: process.env.REACT_APP_API_URL || API_URL
});

apiCall.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME || '');
        config.headers['Content-Type'] = 'application/json';
        if (token && !config.url?.includes('login')) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
export default apiCall as AxiosInstance;

export const unAuthorizedInterceptor = () => {
    const history = createBrowserHistory();

    apiCall.interceptors.response.use(
        (response) => response,
        (error) => {
            const { status } = error.response;

            if (status === 403) {
                localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME || '');
                history.push('/login');
                window.location.reload();
            }
            return Promise.reject(error);
        }
    );
};
