import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useAuthorization = () => {
    const history = useHistory();
    const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME || '');

    useEffect(() => {
        if (token) {
            history.push('/');
        }
    }, [history, token]);
};
