import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
`;

export const Wrapper = styled.div`
    width: 100%;
    height: calc(100% - 70px);
    background: #fafafa;
    display: flex;
`;

export const LoaderWrapper = styled.div`
    width: 100%;
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
`;
