import { IBatch, IBatchDocument, IBatchVideo, IDocType, IVideoType } from '../../interfaces/batch.interface';
import { batchTypes } from '../types/batch.types';

export const batchSearchRequest = (id: string | number, callback: (id: number) => void, autoCreate: boolean) => ({
    type: batchTypes.BATCH_SEARCH_REQUEST,
    payload: { callback, id, autoCreate },
    request: {
        url: `/batches/${id}/`,
        method: 'GET'
    }
});

export const batchSearchSuccess = (data: IBatch) => ({
    type: batchTypes.BATCH_SEARCH_SUCCESS,
    payload: data
});

export const batchSearchReset = () => ({
    type: batchTypes.BATCH_SEARCH_RESET
});

export const batchSearchFailure = (error: string) => ({
    type: batchTypes.BATCH_SEARCH_FAILURE,
    payload: error
});

export const addVideoToBatchRequest = (batchId: string, videoUrl: string, videoTypeId: number | null) => ({
    type: batchTypes.ADD_VIDEO_TO_BATCH_REQUEST,
    request: {
        url: '/videos/',
        method: 'POST',
        data: { batch: batchId, url: videoUrl, video_type_id: videoTypeId }
    }
});

export const batchEditVideoRequest = (videoUrl: string, videoId?: number) => ({
    type: batchTypes.BATCH_EDIT_VIDEO_REQUEST,
    request: {
        url: `/videos/${videoId}/`,
        method: 'PATCH',
        data: { url: videoUrl }
    }
});

export const addVideoToBatchSuccess = (data: IBatchVideo) => ({
    type: batchTypes.ADD_VIDEO_TO_BATCH_SUCCESS,
    payload: data
});

export const addVideoToBatchFailre = (error: string) => ({
    type: batchTypes.ADD_VIDEO_TO_BATCH_FAILURE,
    payload: error
});

export const batchEditVideoSuccess = (data: IBatchVideo) => ({
    type: batchTypes.BATCH_EDIT_VIDEO_SUCCESS,
    payload: { id: data.id, data }
});

export const batchEditVideoFailure = (error: string) => ({
    type: batchTypes.BATCH_EDIT_VIDEO_FAILURE,
    payload: error
});

export const deleteVideoFromBatchRequest = (videoId: number) => ({
    type: batchTypes.BATCH_DELETE_VIDEO_REQUEST,
    payload: { id: videoId },
    request: {
        url: `/videos/${videoId}/`,
        method: 'DELETE'
    }
});

export const deleteVideoFromBatchSuccess = (id: number) => ({
    type: batchTypes.BATCH_DELETE_VIDEO_SUCCESS,
    payload: id
});

export const deleteVideoFromBatchFailure = (error: string) => ({
    type: batchTypes.BATCH_DELETE_VIDEO_FAILURE,
    payload: error
});

export const addDocToBatchRequest = (data: FormData, callback: (data: IBatchDocument) => void) => ({
    type: batchTypes.ADD_DOC_TO_BATCH_REQUEST,
    payload: { callback },
    request: {
        url: `/documents/`,
        method: 'post',
        data
    }
});

export const addDocToBatchSuccess = (data: IBatchDocument) => ({
    type: batchTypes.ADD_DOC_TO_BATCH_SUCCESS,
    payload: data
});

export const addDocToBatchFailure = (error: string) => ({
    type: batchTypes.ADD_DOC_TO_BATCH_FAILURE,
    payload: error
});

export const batchGetVideoTypesRequest = () => ({
    type: batchTypes.BATCH_GET_VIDEO_TYPES_REQUEST,
    request: {
        url: '/videos-type/',
        method: 'GET'
    }
});
export const batchGetVideoTypesSuccess = (data: IVideoType[]) => ({
    type: batchTypes.BATCH_GET_VIDEO_TYPES_SUCCESS,
    payload: data
});

export const batchGetVideoTypesFailure = (error: string) => ({
    type: batchTypes.BATCH_GET_DOCTYPES_FAILURE,
    payload: error
});

export const batchGetDocTypesRequest = () => ({
    type: batchTypes.BATCH_GET_DOCTYPES_REQUEST,
    request: {
        url: '/documents-type/',
        method: 'GET'
    }
});

export const batchGetDocTypesSuccess = (data: IDocType[]) => ({
    type: batchTypes.BATCH_GET_DOCTYPES_SUCCESS,
    payload: data
});

export const batchGetDocTypesFailure = (error: string) => ({
    type: batchTypes.BATCH_GET_DOCTYPES_FAILURE,
    payload: error
});

export const deleteDocFromBatchRequest = (id: number | null, callback: (id: number) => void) => ({
    type: batchTypes.BATCH_DELETE_DOC_REQUEST,
    payload: { callback, id },
    request: {
        url: `/documents/${id}/`,
        method: 'DELETE'
    }
});

export const deleteDocFromBatchSuccess = (id: number) => ({
    type: batchTypes.BATCH_DELETE_DOC_SUCCESS,
    payload: id
});

export const deleteDocFromBatchFailure = (error: string) => ({
    type: batchTypes.BATCH_DELETE_DOC_FAILURE,
    payload: error
});

export const createBatchRequest = (data: { batch: string }, callback: () => void) => ({
    type: batchTypes.BATCH_CREATE_REQUEST,
    payload: { callback },
    request: {
        url: `/batches/`,
        method: 'post',
        data
    }
});

export const createBatchSuccess = (data: IBatch) => ({
    type: batchTypes.BATCH_CREATE_SUCCESS,
    payload: data
});

export const createBatchFailure = (error: string) => ({
    type: batchTypes.BATCH_CREATE_FAILURE,
    payload: error
});

export const getBatchesRequest = () => ({
    type: batchTypes.GET_BATCHES_REQUEST,
    request: {
        url: `/batches/`,
        method: 'get'
    }
});

export const getBatchesSuccess = (data: IBatch) => ({
    type: batchTypes.GET_BATCHES_SUCCESS,
    payload: data
});

export const getBatchesFailure = (error: string) => ({
    type: batchTypes.GET_BATCHES_FAILURE,
    payload: error
});
