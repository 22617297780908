import React, { ChangeEvent, FC, useState } from 'react';
import * as Styled from './style';
import PassShow from '../../assets/icons/passShow.svg';
import PassHide from '../../assets/icons/passHide.svg';
import { FieldProps } from 'formik';

interface IProps {
    type: string;
    placeholder?: string;
    label?: string;
    changeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    dir?: string;
}

export const Input: FC<IProps & FieldProps> = ({ type, placeholder, label, changeHandler, field, error, dir }) => {
    const [fieldType, setType] = useState<string>(type || 'text');
    const isPassword = type === 'password';

    const switchType = () => {
        setType(fieldType === 'text' ? 'password' : 'text');
    };

    const defaultProps = field || { onChange: changeHandler };

    return (
        <Styled.InputWrapper dir={dir || 'ltr'}>
            {label || ''}
            <Styled.InputContainer>
                <Styled.InputField
                    type={fieldType}
                    placeholder={placeholder}
                    isPassword={isPassword}
                    error={error || ''}
                    dir={dir || 'ltr'}
                    {...defaultProps}
                />
                {isPassword && (
                    <Styled.InputControl
                        dir={dir || 'ltr'}
                        src={fieldType === 'password' ? PassShow : PassHide}
                        alt=""
                        onClick={switchType}
                    />
                )}
                {error?.length && <Styled.InputError dir={dir || 'ltr'}>{error}</Styled.InputError>}
            </Styled.InputContainer>
        </Styled.InputWrapper>
    );
};
