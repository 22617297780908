import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: #122a4c;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Container = styled.div`
    background: #ffffff;
    box-shadow: 0px 3px 20px #00000029;
    border-radius: 5px;
    width: 575px;
    height: 693px;
    padding: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

export const Logo = styled.img`
    margin-bottom: 60px;
`;

export const Title = styled.div`
    color: #4d4d4d;
    font-weight: 700;
    font-size: 3rem;
    line-height: 41px;
`;

export const Subtitle = styled.div`
    color: #4d4d4d;
    font-size: 1.6rem;
    line-height: 30px;
`;

export const Texts = styled.div`
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Fields = styled.div`
    margin-bottom: 95px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 342px;
    & > * {
        margin-bottom: 32px;
    }
    & > *:last-child {
        margin-bottom: 0;
    }
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`;

export const ErrorBlock = styled.div`
    position: absolute;
    color: red;
    font-size: 1.4rem;
    width: 100%;
    text-align: center;
    bottom: calc(100% + 15px);
`;

export const LanguageContainer = styled.div`
    position: absolute;
    top: 5px;
    left: 5px;
    &[dir='rtl'] {
        left: auto;
        right: 5px;
    }
`;
